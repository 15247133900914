import React from "react";
import styled from "styled-components";
import { PageProps, graphql } from "gatsby";
import { BlocksContent, Breadcrumb } from "@global";
import { Query } from "@graphql-types";
import Hero from "@shared/hero";
import {
  Container,
  HeroH2,
  GridContainer,
  GridColumnSpan,
  H2,
} from "@util/standard";
import Layout from "@shared/layout";
import SEO from "@shared/seo";
import { BreadcrumbPageContext } from "@util/types";
import { colors } from "@util/constants";
import { navigate } from "gatsby";
import JotformEmbed from "react-jotform-embed";

const ContentContainer = styled(Container)`
  width: 80%;
  flex-direction: column;
  margin: 5% auto;
`;

interface Props extends PageProps {
  data: Query;
  pageContext: BreadcrumbPageContext;
}

const OnlineOrderPage = ({ pageContext, data }: Props) => {
  const sanityOnlineOrderPage = data.allSanityOnlineOrderPage.nodes[0];

  if (sanityOnlineOrderPage == null) {
    return null;
  }

  return (
    <>
      <SEO seoData={sanityOnlineOrderPage.seo} />
      <Layout>
        <Hero height="650px" sanityHero={sanityOnlineOrderPage?.hero} />
        <Breadcrumb pageContext={pageContext} />
        <ContentContainer>
          <GridContainer repeat={6} mobileRepeat={1} tabletRepeat={1}>
            <GridColumnSpan gridColumnWidth={6} paddingBottom={20}>
              <a onClick={() => navigate(-1)}>&lt; Back</a>
            </GridColumnSpan>
            <GridColumnSpan gridColumnWidth={2}>
              <H2 fontFamily="Poppins">
                {sanityOnlineOrderPage?.orderPageTitle}
              </H2>
              <BlocksContent
                blocks={sanityOnlineOrderPage.orderPageInfo?._rawBlocks}
              />
            </GridColumnSpan>
            <GridColumnSpan gridColumnWidth={4} width={100}>
              <JotformEmbed src={process.env.GATSBY_JOTFORM_ONLINEORDER} />
            </GridColumnSpan>
          </GridContainer>
        </ContentContainer>
      </Layout>
    </>
  );
};

export default OnlineOrderPage;

export const query = graphql`
  query allOnlineOrderQuery($iban: String) {
    allSanityOnlineOrderPage(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        hero {
          ...sanityHero
        }
        seo {
          ...sanitySeo
        }
        orderPageInfo {
          _key
          blocks {
            style
            _type
            _rawChildren
            _key
          }
          _type
          _rawBlocks
        }
        orderPageTitle
      }
    }
  }
`;
